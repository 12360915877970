<template>
  <!-- :color="currentTheme.barDark ? 'white' : undefined" -->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :color="
      currentTheme.barImageShow && !$vuetify.theme.dark
        ? 'white'
        : currentTheme.barDark && !$vuetify.theme.dark
        ? currentTheme.color
        : undefined
    "
    :dark="currentTheme.barDark || $vuetify.theme.dark"
    :expand-on-hover="currentTheme.barExpandOnHover"
    :right="$vuetify.rtl"
    :src="
      !$vuetify.theme.dark && currentTheme.barImageShow
        ? currentTheme.barImage
        : ''
    "
    mobile-breakpoint="960"
    app
    :clipped="currentTheme.navClippedLeft"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="
          !$vuetify.theme.dark &&
          currentTheme.barImageShow &&
          currentTheme.barColor
            ? currentTheme.barColor
            : undefined
        "
        v-bind="props"
      />
    </template>

    <v-list
      :dense="false"
      :two-line="false"
      :three-line="false"
      :shaped="false"
      :nav="true"
      :rounded="false"
    >
      <!-- :flat="$vuetify.theme.dark || currentTheme.barDark ? false : true" -->

      <v-list-item
        two-line
        v-if="currentUser"
      >
        <v-list-item-avatar>
          <!-- <v-icon>mdi-vimeo</v-icon> -->
          <img :src="currentUser.picture.xx" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ currentUser.username }}</v-list-item-title>
          <v-list-item-subtitle>{{ currentUser.rolesText }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider></v-divider> -->
      <template v-if="appAclfetching">
        <base-wee-sketlon-loader
          :loading="appAclfetching"
          type="list-item-avatar"
          :no="5"
          list
        />
      </template>
      <template v-else-if="appAclError">
        <wee-error
          :image="true"
          :message="appAclError"
        />
      </template>
      <v-list-item-group
        v-else
        :color="$vuetify.theme.dark || currentTheme.barDark ? '' : 'primary'"
      >
        <div
          v-for="groupItem in appAcls"
          :key="groupItem.header"
        >
          <v-subheader
            v-if="!currentTheme.barExpandOnHover && groupItem.header"
            class="pl-3 py-4 subtitle-1 text-uppercase"
          >{{ $t(groupItem.header) }}</v-subheader>
          <template v-for="(parentItem, i) in groupItem.pages">
            <template v-if="parentItem.items">
              <v-list-group
                :key="`parent-${i}-${parentItem.title}`"
                no-action
                :color="
                  currentTheme.barDark || $vuetify.theme.dark ? 'white' : ''
                "
              >
                <template v-slot:prependIcon>
                  <v-icon
                    v-if="parentItem.icon"
                    :color="currentTheme.barIconColor && parentItem.color ? parentItem.color  : ''"
                  >{{ parentItem.icon }}</v-icon>
                  <template v-else-if="parentItem.iconText">
                    <v-avatar
                      size="24"
                      :color="currentTheme.barIconColor && parentItem.color ? parentItem.color  : (!$vuetify.theme.dark && !currentTheme.barDar ? 'blue-grey lighten-5' : '')"
                    >
                      <span>{{parentItem.iconText}}</span>
                    </v-avatar>
                  </template>

                </template>

                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t(parentItem.title)"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(subItem, subI) in parentItem.items"
                  :key="`parent-${i}-drawer-item-${subI}`"
                  link
                  :to="subItem.to"
                >
                  <v-list-item-action v-if="subItem.icon">
                    <v-icon
                      :color="currentTheme.barIconColor &&  subItem.color ? subItem.color  : ''"
                      small
                    >{{ subItem.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ $t(subItem.title) }}</span>
                        </template>
                        <span>{{ $t(subItem.title) }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
            <template v-else>
              <v-list-item
                :key="`drawer-item-${i}`"
                link
                class="mb-0"
                router
                :to="parentItem.to"
                exact
              >
                <v-list-item-icon>
                  <v-icon :color="currentTheme.barIconColor && parentItem.color ? parentItem.color  : ''">{{ parentItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(parentItem.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </div>
      </v-list-item-group>
    </v-list>

    <!-- <template
      v-slot:append
      v-if="!currentTheme.barExpandOnHover"
    >
      <v-row
        no-gutters
        class="my-5"
      >
        <v-col
          cols="8"
          class="pl-1"
        >
          <v-text-field
            hide-details
            :placeholder="$t('base.search')"
            filled
            rounded
            dense
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col
          cols="4"
          class="text-center "
        >
          <v-btn
            icon
            class="mr-1"
            to="profile/settings"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-share-variant</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
import useSiteSetting from "@/composition/UseSiteSetting";
import useMenuApi from "@/composition/UseMenuApi";
import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
  name: "CoreDrawer",
  components: {
    WeeError: () => import("@/components/WeeError")
  },
  setup(props, { root }) {
    const { $store } = root;
    const { currentTheme, setDrawer, currentUser } = useSiteSetting(root);
    const { appAcls, appAclfetching, appAclError } = useMenuApi();
    const drawer = computed({
      get: () => $store.state.drawer,
      set: val => {
        setDrawer(val);
      }
    });

    return {
      currentTheme,
      drawer,
      appAcls,
      appAclfetching,
      appAclError,
      currentUser
    };
  }
});
</script>