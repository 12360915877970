import { reactive, toRefs, onMounted } from "@vue/composition-api";
import UserService from "@/api/UserService";
export default function () {
  const state = reactive({
    appAcls: [],
    appAclError: null,
    appAclfetching: false
  });

  //to be update fetch acl menu from server
  onMounted(() => {
    fetchMenuAcl();
  });
  const fetchMenuAcl = async () => {
    state.appAclfetching = true;
    const userService = new UserService();
    const { response, error } = await userService.userAcl();
    state.appAclfetching = false;
    state.appAclError = error;
    if (response && response.length > 0) {
      state.appAcls = response;
    }
  }

  return {
    ...toRefs(state)
  };
}
